// import axios from "axios"
import axios from "./AxiosSetup";

export const fetchCompanyDetails = async (companyId) =>{

    try{
        const token = localStorage.getItem('token'); // Replace 'token' with your actual token key

      const resp = await axios.get(`${process.env.REACT_APP_API_URL}/api/Companies/${companyId}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }})
         return resp.data;

    }
    catch(err)
    {
        throw "Error"
    }
}

export const fetchDashboardDetails = async (companyId) =>{

    try{
        const token = localStorage.getItem('token'); // Replace 'token' with your actual token key

      const resp = await axios.get(`${process.env.REACT_APP_API_URL}/api/Dashboard/${companyId}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }})
         return resp.data;

    }
    catch(err)
    {
        throw "Error"
    }
}

export const fetchCompanies = async () =>{

    try{
        const token = localStorage.getItem('token'); // Replace 'token' with your actual token key

      const resp = await axios.get(`${process.env.REACT_APP_API_URL}/api/Companies`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }})
         return resp.data;

    }
    catch(err)
    {
        throw "Error"
    }

}

export const fetchScriptsForCompany = async(companyId) =>{
    try{
        const token = localStorage.getItem('token'); // Replace 'token' with your actual token key

      const resp = await axios.get(`${process.env.REACT_APP_API_URL}/api/Script/${companyId}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }})
         return resp.data;

    }
    catch(err)
    {
        throw "Error"
    }
}

export const fetchNotificationsForCompany = async(companyId) =>{
    try{
        const token = localStorage.getItem('token'); // Replace 'token' with your actual token key

      const resp = await axios.get(`${process.env.REACT_APP_API_URL}/api/Notification/ForCompany/${companyId}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }})
         return resp.data;

    }
    catch(err)
    {
        throw "Error"
    }
}

export const getLoggedInUserCompany = async() =>{
    try{
        const token = localStorage.getItem('token'); // Replace 'token' with your actual token key
        const email = localStorage.getItem('email');

      const resp = await axios.get(`${process.env.REACT_APP_API_URL}/api/Account/getUserCompany/${email}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }})
         return resp.data;

    }
    catch(err)
    {
        throw "Error"
    }
}

export const fetchActiveCompanyUsers = async(companyid) =>{
    try{
        const token = localStorage.getItem('token'); // Replace 'token' with your actual token key

      const resp = await axios.get(`${process.env.REACT_APP_API_URL}/api/Account/getCompanyUsers/${companyid}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }})
         return resp.data;

    }
    catch(err)
    {
        throw "Error"
    }
}

export const fetchCompanyManager = async(companyid) =>{
    try{
        const token = localStorage.getItem('token'); // Replace 'token' with your actual token key

      const resp = await axios.get(`${process.env.REACT_APP_API_URL}/api/Account/getCompanyManager/${companyid}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }})
         return resp.data;

    }
    catch(err)
    {
        throw "Error"
    }
}

export const fetchUserDashboard = async() =>{
    try{
        const token = localStorage.getItem('token'); // Replace 'token' with your actual token key
        const email = localStorage.getItem('email'); // Replace 'token' with your actual token key

      const resp = await axios.get(`${process.env.REACT_APP_API_URL}/api/Dashboard/getUserDashboard/${email}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }})
        if(resp.status===401)
            {
                manageTokenUnauthorized();
                
            }
         return resp.data;

    }
    catch(err)
    {
        throw "Error"
    }
}


export const manageTokenUnauthorized = () =>{
    alert("Your session is timed out, Login again.")
    localStorage.clear();
    window.location = window.location.origin + "/login";
}


export const fetchPhoneNumbersForScript = async(ScriptId) =>{
    try{
        const token = localStorage.getItem('token'); // Replace 'token' with your actual token key

      const resp = await axios.get(`${process.env.REACT_APP_API_URL}/api/Notification/GetPhNumbers/${ScriptId}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }})
         return resp.data;

    }
    catch(err)
    {
        throw "Error"
    }
}
