import axios from "./AxiosSetup";
import { getLoggedInUserCompany } from "./DataServices";

export const createCompany = (companyData) => {
    // Retrieve the auth token from local storage
    const authToken = localStorage.getItem('token');

    // Set up the Axios configuration for the POST request
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`  // Assume the token is a Bearer token
        }
    };

    // URL of the API endpoint
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/Companies`;  // Replace with your actual API endpoint

    // Make the POST request
    return axios.post(apiUrl, companyData, config)
        .then(response => {
            console.log('Company created successfully:', response.data);
            return response.data;  // Return the response data
        })
        .catch(error => {
            console.error('Error creating company:', error.response ? error.response.data : error);
            throw error;  // Re-throw the error to be handled by the caller
        });
};

export const createScript = (scriptData) =>{
     // Retrieve the auth token from local storage
     const authToken = localStorage.getItem('token');

     // Set up the Axios configuration for the POST request
     const config = {
         headers: {
             'Content-Type': 'application/json',
             'Authorization': `Bearer ${authToken}`  // Assume the token is a Bearer token
         }
     };
 
     // URL of the API endpoint
     const apiUrl = `${process.env.REACT_APP_API_URL}/api/Script`;  // Replace with your actual API endpoint
 
     // Make the POST request
     return axios.post(apiUrl, scriptData, config)
         .then(response => {
             console.log('Company created successfully:', response.data);
             return response.data;  // Return the response data
         })
         .catch(error => {
             console.error('Error creating company:', error.response ? error.response.data : error);
             throw error;  // Re-throw the error to be handled by the caller
         });
}

export const CreateNotification = async(notificationData) =>{
     // Retrieve the auth token from local storage
     const authToken = localStorage.getItem('token');

     // Set up the Axios configuration for the POST request
     const config = {
         headers: {
             'Content-Type': 'application/json',
             'Authorization': `Bearer ${authToken}`  // Assume the token is a Bearer token
         }
     };
 
     // URL of the API endpoint
     const apiUrl = `${process.env.REACT_APP_API_URL}/api/Notification/AddNotificationToScript`;  // Replace with your actual API endpoint
 
     // Make the POST request
     return axios.post(apiUrl, notificationData, config)
         .then(response => {
             console.log('Noti created successfully:', response.data);
             return response.data;  // Return the response data
         })
         .catch(error => {
             console.error('Error creating notification:', error.response ? error.response.data : error);
             throw error;  // Re-throw the error to be handled by the caller
         });
}

export const CreateUser = async (companyID, UserData) => {
    try {
        // First, fetch the company ID of the logged-in user
        UserData ={...UserData, companyID:companyID};


        // Retrieve the auth token from local storage
        const authToken = localStorage.getItem('token');

        // Set up the Axios configuration for the POST request
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`  // Assume the token is a Bearer token
            }
        };

        // URL of the API endpoint
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/Account/registerUser`;  // Replace with your actual API endpoint

        // Make the POST request
        const response = await axios.post(apiUrl, UserData, config);
        console.log('User created successfully:', response.data);
        return response.data;  // Return the response data

    } catch (error) {
        console.error('Error creating user:', error.response.data);
        throw error.response.data;  // Re-throw the error to be handled by the caller
    }
};


export const CreateManager = async(companyID,UserData) =>{
    // Retrieve the auth token from local storage
    const authToken = localStorage.getItem('token');
    UserData ={...UserData, companyID:companyID};

    // Set up the Axios configuration for the POST request
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`  // Assume the token is a Bearer token
        }
    };
    
    // URL of the API endpoint
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/Account/registerManager`;  // Replace with your actual API endpoint
    
    // Make the POST request
    return axios.post(apiUrl, UserData, config)
        .then(response => {
            console.log('User created successfully:', response.data);
            return response.data;  // Return the response data
        })
        .catch(error => {
            console.error('Error creating User:', error.response ? error.response.data : error);
            throw error.response.data;  // Re-throw the error to be handled by the caller
        });
    }


export const AddPhNumberToScript = async(NewNumberData) =>{
 // Retrieve the auth token from local storage
 const authToken = localStorage.getItem('token');
    
 // Set up the Axios configuration for the POST request
 const config = {
     headers: {
         'Content-Type': 'application/json',
         'Authorization': `Bearer ${authToken}`  // Assume the token is a Bearer token
     }
 };
 
 // URL of the API endpoint
 const apiUrl = `${process.env.REACT_APP_API_URL}/api/Notification/AddPhoneNumberToScript`;  // Replace with your actual API endpoint
 
 // Make the POST request
 return axios.post(apiUrl, NewNumberData, config)
     .then(response => {
         console.log('User created successfully:', response.data);
         return response.data;  // Return the response data
     })
     .catch(error => {
         console.error('Error creating User:', error.response ? error.response.data : error);
         throw error;  // Re-throw the error to be handled by the caller
     });
 }


 export const createDashboard = (dashboardData) =>{
    // Retrieve the auth token from local storage
    const authToken = localStorage.getItem('token');

    // Set up the Axios configuration for the POST request
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`  // Assume the token is a Bearer token
        }
    };

    // URL of the API endpoint
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/Dashboard`;  // Replace with your actual API endpoint

    // Make the POST request
    return axios.post(apiUrl, dashboardData, config)
        .then(response => {
            console.log('Company created successfully:', response.data);
            return response.data;  // Return the response data
        })
        .catch(error => {
            console.error('Error creating company:', error.response ? error.response.data : error);
            throw error;  // Re-throw the error to be handled by the caller
        });
}