import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Image from "../Assets/Images/techcore3.jpg";
import Logo from "../Assets/Images/TechCore logo.png";
import GoogleSvg from "../Assets/Images/icons8-google.svg";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import "../Styles/login.css"

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (e) => {
    setLoading(true);
    e.preventDefault();
    setError('');

    if (!email || !password) {
      setError('Email and password are required');
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, { email, password });
      localStorage.setItem('token', response.data.accessToken); 
      localStorage.setItem('refreshToken', response.data.refreshToken);
      const token = localStorage.getItem('token'); 

      const loggedInResponse = await axios.get(`${process.env.REACT_APP_API_URL}/manage/info`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
      });
      localStorage.setItem('email', loggedInResponse.data.email);
      const UserTabs = await axios.get(`${process.env.REACT_APP_API_URL}/api/Account/getUserAccess/${localStorage.getItem('email')}`, {
          headers: {
              'Authorization': `Bearer ${token}`
          }
      });
      localStorage.setItem('routes', UserTabs.data.join());
      setLoading(false);
      navigate('/dashboard');
    } catch (err) {
      setLoading(false);
      if(err.toString().includes("401")) {
        setError("Incorrect email or password!");
      } else {
        setError('Failed to login: ' + err);
      }
    }
  };

  return (
    <div className="login-main">
      <div className="login-left">
        <img src={Image} alt="" />
      </div>
      <div className="login-right">
        <div className="login-right-container">
          <div className="login-logo">
            <img src={Logo} alt="" />
          </div>
          <div className="login-center">
            <h2>Welcome back!</h2>
            <p>Please enter your details</p>
            <form onSubmit={handleLogin}>
              <input 
                type="email" 
                placeholder="Email" 
                value={email} 
                onChange={(e) => setEmail(e.target.value)} 
                required 
              />
              <div className="pass-input-div">
                <input 
                  type={showPassword ? "text" : "password"} 
                  placeholder="Password" 
                  value={password} 
                  onChange={(e) => setPassword(e.target.value)} 
                  required 
                />
                {showPassword ? (
                  <EyeSlash onClick={togglePasswordVisibility} />
                ) : (
                  <Eye onClick={togglePasswordVisibility} />
                )}
              </div>

              <div className="login-center-options">
                {/* <div className="remember-div">
                  <input type="checkbox" id="remember-checkbox" />
                  <label htmlFor="remember-checkbox">
                    Remember for 30 days
                  </label>
                </div> */}
                {/* <a href="#" className="forgot-pass-link">
                  Forgot password?s
                </a> */}
              </div>
              <div className="login-center-buttons">
                <button type="submit">Log In</button>
                {/* <button type="button">
                  <img src={GoogleSvg} alt="" />
                  Log In with Google
                </button> */}
              </div>
              {error && <div className="alert alert-danger mt-2">{error}</div>}
              {loading && <div className='alert alert-info mt-2'>Logging in...</div>}
            </form>
          </div>

          {/* <p className="login-bottom-p">
            Don't have an account? <a href="#">Sign Up</a>
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default Login;
