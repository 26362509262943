import axios from 'axios';

const API_URL = 'https://your-api.com';
const REFRESH_URL = `${process.env.REACT_APP_API_URL}/refresh`;
const LOGIN_URL = `${process.env.REACT_APP_API_URL}/login`; // Define login URL


// Function to refresh the access token
const refreshToken = async () => {
  try {
    const { data } = await axios.post(REFRESH_URL, {
        refreshToken: localStorage.getItem('refreshToken'), // Assuming refresh token is stored in localStorage
    });
    localStorage.setItem('token', data.accessToken); // Update the access token in storage
    return data.accessToken;
  } catch (error) {
    console.error('Error refreshing token:', error);
    throw error;
  }
};

// Axios interceptor to handle token expiration
axios.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    // Skip interceptor for login request
    if (originalRequest.url === LOGIN_URL) {
      return Promise.reject(error);
    }
    if (error.response.status === 401 && !originalRequest._retry && originalRequest.url!=REFRESH_URL) {
      originalRequest._retry = true;
      const newAccessToken = await refreshToken();
      axios.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
      originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
      return axios(originalRequest);
    }
    return Promise.reject(error);
  }
);

// Function to set up initial authorization headers
const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
};

// Initialize Axios with existing token at app start
// setAuthToken(localStorage.getItem('accessToken'));

export default axios;
