import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchCompanyDetails } from '../Services/DataServices';
import Sidebar from '../Components/Sidebar';
import Navbar from '../Components/Navbar';
import { updateCompanyDetails } from '../Services/UpdateServices';
import ScriptDetails from '../Components/ScriptDetails';
import DashboardEdit from '../Components/DashboardEdit';
import ManagerDetails from '../Components/ManagerDetails';
import UserDetails from '../Components/UserDetails';
import { NotificationsDD } from '../Components/NotificationsDD';
import "../Styles/config.css";

const CompanyConfig = () => {
    const { companyId } = useParams();
    const [company, setCompany] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editedCompany, setEditedCompany] = useState(null);

    useEffect(() => {
        fetchCompanyDetails(companyId).then(data => {
            setCompany(data);
            setEditedCompany({ ...data });  // Initialize editedCompany state with fetched data
        });
    }, [companyId]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedCompany(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const toggleEdit = () => {
        setIsEditing(!isEditing);
        if (isEditing) {
            // Save the changes
            updateCompanyDetails(companyId, editedCompany).then(response => {
                console.log('Company updated successfully:', response.data);
                setCompany(editedCompany); // Update the main company state with the new details
            })
                .catch(error => {
                    console.error('Failed to update company:', error);
                });
        } else {
            // Reset any changes if cancelled
            setEditedCompany({ ...company });
        }
    };



    if (!company) return <div>Loading...</div>;

    return (
        <div className="layout">
            <Navbar />
            <div className="content">
                <Sidebar />
                <div className="main-content">
                <div className="d-flex flex-column justify-content-center">
                <div className='container configSection mb-3 p-2'>
                    <h1>Company Configuration: {editedCompany && editedCompany.companyName}</h1>
                    <p>Here you can view or edit the configurations for this company.</p>
                    <button className="btn btn-primary" onClick={toggleEdit}>
                        {isEditing ? 'Save Changes' : 'Edit'}
                    </button>
                    {isEditing && (
                        <button className="btn btn-secondary ms-2" onClick={() => {
                            setIsEditing(false);
                            setEditedCompany({ ...company }); // Revert changes
                        }}>
                            Cancel
                        </button>
                    )}

                    <form>
                        <div className="mb-3">
                            <label htmlFor="companyName" className="form-label">Company Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="companyName"
                                name="companyName"
                                value={editedCompany.companyName}
                                onChange={handleInputChange}
                                disabled={!isEditing}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="uniqueClientId" className="form-label">Unique Client ID</label>
                            <input
                                type="text"
                                className="form-control"
                                id="uniqueClientId"
                                name="uniqueClientID"
                                value={editedCompany.uniqueClientID}
                                onChange={handleInputChange}
                                disabled={!isEditing}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="pan" className="form-label">PAN</label>
                            <input
                                type="text"
                                className="form-control"
                                id="pan"
                                name="pan"
                                value={editedCompany.pan}
                                onChange={handleInputChange}
                                disabled={!isEditing}
                            />
                        </div>
                        {/* Add more fields as needed */}
                    </form>
                </div>
                <div className='container configSection mb-3 p-2'>
                    <DashboardEdit companyId={companyId} />
                </div>
                <div className='container configSection mb-3 p-2'>
                    <ManagerDetails company={company} />
                </div>
                <div className='container configSection mb-3 p-2'>
                    <UserDetails company={company} />
                </div>
                <div className='container configSection mb-3 p-2'>
                    <ScriptDetails company={company} />
                </div>
                <div className='container configSection mb-3 p-2'>
                    <NotificationsDD company={company} />
                </div>

            </div>
                </div>
            </div>
        </div>
    );
};

export default CompanyConfig;
