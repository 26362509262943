import React from 'react';
import logo from '../Assets/Images/TechCore logo.png';

const Navbar = () => {
    const email = localStorage.getItem("email")
  return (
    <nav className="navbar">
      <div className="container-fluid">
        <img src={logo} alt="Company Logo" className="mb-1" style={{ height: '45px' }} />
        <div className="d-inline-flex">
          <div className="navbar-text text-white">
            {email}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
