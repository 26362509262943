import React, { useState, useEffect } from 'react';

const AddScript = ({ show, onClose, onSave, company}) => {
    const [scriptName, setScriptName] = useState('');
    const [deviceName, setDeviceName] = useState('');
    const [machineName, setMachineName] = useState('');
    const [notiTitle, setNotiTitle] = useState('');

    useEffect(() => {
        if (show) {
            setScriptName('');
            setDeviceName('');
            setMachineName('');
            setNotiTitle('');
        }
    }, [show]);

    const handleSubmit = (event) => {
        event.preventDefault();
        onSave({
            scriptId: 0,
            scriptName,
            deviceName,
            machineName,
            notificationTitle:notiTitle,
            companyID:company.companyID
        });
    };

    if (!show) return null;

    return (
        <div className="modal show d-block" tabIndex="-1" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Add New Script for {company.companyName}</h5>
                        <button type="button" className="btn-close" onClick={onClose}></button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="scriptName" className="form-label">Script Name</label>
                                <input type="text" className="form-control" id="scriptName" value={scriptName} onChange={e => setScriptName(e.target.value)} required />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="deviceName" className="form-label">Device Name</label>
                                <input type="text" className="form-control" id="deviceName" value={deviceName} onChange={e => setDeviceName(e.target.value)} required />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="machineName" className="form-label">Machine Name</label>
                                <input type="text" className="form-control" id="machineName" value={machineName} onChange={e => setMachineName(e.target.value)} required />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="notificationTitle" className="form-label">Notification Title</label>
                                <input type="text" className="form-control" id="notificationTitle" value={notiTitle} onChange={e => setNotiTitle(e.target.value)} required />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={onClose}>Close</button>
                                <button type="submit" className="btn btn-primary">Save Script</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddScript;
