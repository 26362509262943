import React, { useEffect, useState } from 'react';
import Sidebar from '../Components/Sidebar';
import Navbar from '../Components/Navbar';
import CompanyCard from '../Components/CompanyCard';
import AddCompany from '../Components/AddCompany';
import { fetchCompanies } from '../Services/DataServices';
import { createCompany } from '../Services/CreateServices';

const Admin = () => {
    const [companies, setCompanies] = useState([]);
    const [refresh, setRefresh] = useState(false);  // State to trigger useEffect when updated

    // Fetch companies either on initial load or when refresh is toggled
    useEffect(() => {
        fetchCompanies().then((d) => {
            setCompanies(d);
        }).catch(e => console.log(e));
    }, [refresh]);  // Depend on refresh state

    const [showModal, setShowModal] = useState(false);

    const addNewCompany = (company) => {
        setShowModal(false);
        createCompany(company)
            .then(r => {
                console.log(r);
                setRefresh(!refresh);  // Toggle the refresh state to trigger re-fetching of companies
            })
            .catch(e => console.log(e));
    };

    return (
        <div className="layout">
            <Navbar />
            <div className="content">
                <Sidebar />
                <div className="main-content">
                    <button className="btn btn-primary mb-3" onClick={() => setShowModal(true)}>Add New Company</button>
                    <div className="row">
                        {companies.map(company => (
                            <CompanyCard key={company.companyID} company={company} />
                        ))}
                    </div>
                    <AddCompany show={showModal} onClose={() => setShowModal(false)} onSave={addNewCompany} />
                </div>
            </div>
        </div>
    );
};

export default Admin;
