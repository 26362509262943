import React, { useEffect, useState } from 'react';
import ConfirmModal from './ConfirmModal';
import AddUser from './AddUser';
import { Eye, EyeSlash, Trash, ArrowClockwise, PersonAdd } from 'react-bootstrap-icons';
import { fetchCompanyManager } from '../Services/DataServices';
import { CreateManager } from '../Services/CreateServices';
import ResetPasswordModal from './ResetPasswordModal';
import { UpdateUserPassword } from '../Services/UpdateServices';
import AppToast from '../Components/AppToast';
import { deleteUser } from '../Services/DeleteServices';


const ManagerDetails = ({company}) => {
    const [users, setUsers] = useState([]);
    const [editUserId, setEditUserId] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const [modalAddShow, setModalAddShow] = useState(false);
    const [originalData, setOriginalData] = useState({}); // Store original data in case of cancel
    const [currentUser, setCurrentUser] = useState(null);
    const [modalResetPasswordShow, setModalResetPasswordShow] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMsg, setToastMsg] = useState("");
    const [toastType, setToastType] = useState("success"); 

    useEffect(() => {
        fetchCompanyManager(company.companyID).then(d=> setUsers(d)).catch(e=>console.log(e));
    }, [refresh]);  // Depend on refresh state


    const handleDelete = (user) => {
        setModalShow(true);
        setCurrentUser(user);
    };

    const confirmDelete = () => {
        setUsers(users.filter(u => u.id !== currentUser.id));
        deleteUser(currentUser.email).then(r => console.log(r)).catch(e => console.log(e));
        setModalShow(false);
        setRefresh(!refresh);
    };

    const handleEdit = (user) => {
        setEditUserId(user.id);
        setOriginalData({ ...user });  // Save original data
    };

    const handleChange = (e, id) => {
        const { name, value } = e.target;
        setUsers(users.map(user => user.id === id ? { ...user, [name]: value } : user));
    };

    const handleSave = () => {
        setEditUserId(null); // Save and exit edit mode
    };

    const handleCancel = () => {
        setUsers(users.map(user => user.id === editUserId ? { ...originalData } : user)); // Revert changes
        setEditUserId(null); // Exit edit mode
    };

    const handleAddNewUser = (newUserData) => {
        console.log(newUserData)
        CreateManager(company.companyID,newUserData)
        .then((r)=>{
            console.log(r);
            setRefresh(!refresh);
        }).catch((e)=>{
            setToastMsg(e);
            setToastType("danger");
            setShowToast(true);
        })
        setModalAddShow(false);
    };

    const handleResetPassword = (user) => {
        setCurrentUser(user);
        setModalResetPasswordShow(true);
    };

    const saveNewPassword = (email, newPassword) => {
        UpdateUserPassword(email, newPassword).then(r=>console.log(r)).catch(e=>console.log(e))
        // Here you would call the service to update the password
        console.log(`Password for ${email} updated to ${newPassword}`);
        setModalResetPasswordShow(false);
    };

    return (
        <div className="container">
            <h2>Manager Accounts</h2>
            <button className="btn btn-primary my-3" onClick={() => setModalAddShow(true)}>Add New Manager <PersonAdd /></button>
            <table className="table table-bordered table-responsive">
                <thead>
                    <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                        {/* <th>Password</th> */}
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map(user => (
                        <tr key={user.id}>
                            {editUserId === user.id ? (
                                <>
                                    <td><input type="text" name="firstName" value={user.firstName} onChange={e => handleChange(e, user.id)} /></td>
                                    <td><input type="text" name="lastName" value={user.lastName} onChange={e => handleChange(e, user.id)} /></td>
                                    <td><input type="email" name="email" value={user.email} onChange={e => handleChange(e, user.id)} /></td>
                                    {/* <td>
                                        <input
                                            type={passwordVisibility[user.id] ? "text" : "password"}
                                            name="password"
                                            value={user.password}
                                            onChange={e => handleChange(e, user.id)}
                                        />
                                        <span className="p-1" onClick={() => togglePasswordVisibility(user.id)}>
                                            {passwordVisibility[user.id] ? <EyeSlash /> : <Eye />}
                                        </span>
                                    </td> */}
                                </>
                            ) : (
                                <>
                                    <td>{user.firstName}</td>
                                    <td>{user.lastName}</td>
                                    <td>{user.email}</td>
                                    {/* <td>{user.password}</td> */}
                                </>
                            )}
                            <td>
                                {editUserId === user.id ? (
                                    <>
                                        <button className="btn btn-success mx-2" onClick={handleSave}>Save</button>
                                        <button className="btn btn-warning" onClick={handleCancel}>Cancel</button>
                                    </>
                                ) : (
                                    <>
                                        {/* <button className="btn btn-info mx-2" onClick={() => handleEdit(user)}>Edit</button> */}
                                        <button className="btn btn-danger mx-2" onClick={() => handleDelete(user)}><Trash /></button>
                                        <button className="btn btn-secondary" onClick={() => handleResetPassword(user)}>Reset Password <ArrowClockwise /></button>
                                    </>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {modalShow && (
                <ConfirmModal
                    show={modalShow}
                    onClose={() => setModalShow(false)}
                    onCancel={()=>setModalShow(false)}
                    onConfirm={confirmDelete}
                    title="Confirm Deletion"
                >
                    Are you sure you want to delete {currentUser?.firstName} {currentUser?.lastName}?
                </ConfirmModal>
            )}
            {modalAddShow && (
                <AddUser show={modalAddShow} onClose={() => setModalAddShow(false)} onSave={handleAddNewUser} />
            )}
             {modalResetPasswordShow && (
                <ResetPasswordModal
                    show={modalResetPasswordShow}
                    onClose={() => setModalResetPasswordShow(false)}
                    onSave={saveNewPassword}
                    onCancel={() => setModalResetPasswordShow(false)}
                    user={currentUser}
                />
            )}
            <AppToast 
             message={toastMsg}
             type={toastType}
             show={showToast}
             onClose={() => setShowToast(false)}
            />
        </div>
    );
};

export default ManagerDetails;
