import React, { useEffect, useState } from 'react';
import { fetchNotificationsForCompany, fetchScriptsForCompany, getLoggedInUserCompany } from '../Services/DataServices';
import { ScriptNumbers } from './ScriptNumbers';
import { ArrowClockwise } from 'react-bootstrap-icons';

export const NotificationsDD = ({ company: initialCompany }) => {
    const [selectedScript, setSelectedScript] = useState(null);
    const [scripts, setScripts] = useState([]);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(true);

    useEffect(() => {
        const fetchNotifications = async () => {
            setLoading(true);
            try {
                const companyData = initialCompany || await getLoggedInUserCompany();
                const scriptsData = await fetchScriptsForCompany(companyData.companyID);
                setScripts(scriptsData);
                console.log(scriptsData)
                setError(false);
            } catch (e) {
                setError(true);
                setScripts([]);
            } finally {
                setLoading(false);
            }
        };
        fetchNotifications();
    }, [initialCompany, refresh]);

    const handleSelectChange = (event) => {
        const scriptId = parseInt(event.target.value, 10);
        const selected = scripts.find(script => script.scriptId === scriptId);
        setSelectedScript(selected);
    };

    const refreshComp =() =>{
        setRefresh(!refresh);
    }

    if (loading) return <div className=' d-flex justify-content-center mb-3'><span className='loader'></span></div>;
    if (error) return <div className='text-center'>No Scripts found or Somthing went wrong</div>;

    return (
        <div className='container mb-3'>
            <h3 className='text-center'>Update Whatsapp Notification Numbers</h3>
            <div className='d-flex align-items-center gap-1'>
    
            <label htmlFor="ScriptID">Select Notification</label>
            <select className="form-control" style={{width:"75%"}} value={selectedScript?.scriptId || ''} onChange={handleSelectChange}>
                <option value="">None</option>
                {scripts.map(script => (
                    <option key={script.scriptId} value={script.scriptId}>
                        {script.notificationTitle}
                    </option>
                ))}
            </select>
            
            <span className="ms-auto refreshbtn" onClick={refreshComp}>Refresh<ArrowClockwise /></span>
            </div>
            {selectedScript && <ScriptNumbers scriptID={selectedScript.scriptId}/>}
        </div>
    );
};
