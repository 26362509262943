import React, { useState } from 'react';
import { Copy, Eye, EyeFill, EyeSlash } from 'react-bootstrap-icons';

const AddUser = ({ show, onClose, onSave }) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState({});

    const validateEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const validatePassword = (password) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return regex.test(password);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const newErrors = {};

        if (!firstName.trim()) newErrors.firstName = 'First name is required.';
        if (!lastName.trim()) newErrors.lastName = 'Last name is required.';
        if (!email.trim()) newErrors.email = 'Email is required.';
        else if (!validateEmail(email)) newErrors.email = 'Please enter a valid email.';
        if (!password.trim()) newErrors.password = 'Password is required.';
        else if (!validatePassword(password)) newErrors.password = 'Password must be at least 8 characters and include at least one uppercase letter, one lowercase letter, one number, and one special character.';

        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            onSave({ firstName, lastName, email, password });
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const copyToClipboard = () => {
        const formattedText = `Email: ${email}\nPassword: ${password}`;
        // navigator.clipboard.writeText(formattedText);
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(formattedText)
              .then(() => {
                alert("Details copied to clipboard successfully!");
              })
              .catch(err => {
                console.error("Failed to copy text: ", err);
                // Fallback method (optional)
                fallbackCopyText(formattedText);
              });
          } else {
            // Fallback method (optional)
            fallbackCopyText(formattedText);
          }
    };

    const fallbackCopyText = (text) => {
        // Create a temporary textarea element
        const textarea = document.createElement("textarea");
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        textarea.setSelectionRange(0, 99999); // For mobile devices
        try {
          document.execCommand('copy');
          alert("Details copied to clipboard successfully!");
        } catch (err) {
          alert("Oops, unable to copy", err);
        }
        document.body.removeChild(textarea);
      };

    if (!show) return null;

    return (
        <div className="modal show d-block" tabIndex="-1" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Add New User</h5>
                        <button type="button" className="btn-close" onClick={onClose}></button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="firstName" className="form-label">First Name</label>
                                <input type="text" className="form-control" id="firstName" value={firstName} onChange={e => setFirstName(e.target.value)} required />
                                {errors.firstName && <div className="text-danger">{errors.firstName}</div>}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="lastName" className="form-label">Last Name</label>
                                <input type="text" className="form-control" id="lastName" value={lastName} onChange={e => setLastName(e.target.value)} required />
                                {errors.lastName && <div className="text-danger">{errors.lastName}</div>}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">Email</label>
                                <input type="email" className="form-control" id="email" value={email} onChange={e => setEmail(e.target.value)} required />
                                {errors.email && <div className="text-danger">{errors.email}</div>}
                            </div>
                            <label htmlFor="password" className="form-label">Password</label>
                            <div className="d-flex gap-2 align-items-center">
                                <input type={showPassword ? "text" : "password"} className="form-control" id="password" value={password} onChange={e => setPassword(e.target.value)} required />
                                <button type="button" className="btn btn-secondary featureBtn" onClick={togglePasswordVisibility}>
                                    {showPassword ? <EyeSlash /> : <EyeFill />}
                                </button>
                                <button type="button" className="btn btn-secondary featureBtn" onClick={copyToClipboard}>
                                    <Copy />
                                </button>
                            </div>
                            {errors.password && <div className="text-danger">{errors.password}</div>}
                            <div className="modal-footer mt-3">
                                <button type="button" className="btn btn-secondary" onClick={onClose}>Close</button>
                                <button type="submit" className="btn btn-primary">Save User</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddUser;
