import React, { useState } from 'react';
import '../Styles/Sidebar.css'; // Ensure your styles are correctly set
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { HouseFill, PeopleFill, GearFill, BoxArrowRight, Whatsapp, List } from 'react-bootstrap-icons';

const Sidebar = () => {
    const navigate = useNavigate();
    const [showLogout, setShowLogout] = useState(false);
    const [isOpen, setIsOpen] = useState(false); // State for sidebar visibility

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const logoutUser = async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            console.error("No token found");
            return;
        }
    
        const confirmation = window.confirm("Are you sure you want to log out?");
        if (!confirmation) {
            return;
        }
    
        try {
            localStorage.clear();
            navigate('/login');
        } catch (error) {
            console.error('Logout failed', error);
        }
    }

    const routes = localStorage.getItem("routes");
    var accessAllowed = [];
    if(routes)
        {
            accessAllowed=routes.split(",");
        }

    return (
        <>
            <div id="mySidebar" className={`sidebar ${isOpen ? 'open' : ''}`}>
                <div className="sidebar-links">
                    {accessAllowed.includes("Dashboard") && <Link to="/dashboard" className="sidebar-link">
                        <HouseFill className="icon" /> 
                        <span className="link-text">Dashboard</span>
                    </Link>}
                    {accessAllowed.includes("Users") &&<Link to="/users" className="sidebar-link">
                        <PeopleFill className="icon" /> 
                        <span className="link-text">Users</span>
                    </Link>}
                    {accessAllowed.includes("Notification") &&<Link to="/notification" className="sidebar-link">
                        <Whatsapp className="icon" /> 
                        <span className="link-text">Notifications</span>
                    </Link>}
                    {accessAllowed.includes("Manage") &&<Link to="/admin" className="sidebar-link">
                        <GearFill className="icon" /> 
                        <span className="link-text">Admin</span>
                    </Link>}
                </div>
                <div className="sidebar-link logout" onClick={logoutUser}>
                    <span className="sidebar-link">
                        <BoxArrowRight className="icon" /> 
                        <span className="link-text">Logout</span>
                    </span>
                </div>
            </div>
            {/* <button className="hamburger" onClick={toggleSidebar}>
                <List className="hamburger-icon" />
            </button> */}
        </>
    );
};

export default Sidebar;
