import React, { useEffect, useState } from 'react';
import Navbar from '../Components/Navbar';
import Sidebar from '../Components/Sidebar';
import UserDetails from '../Components/UserDetails';


const Users = () => {
    // const [users, setUsers] = useState([]);
    // const [editUserId, setEditUserId] = useState(null);
    // const [modalShow, setModalShow] = useState(false);
    // const [modalAddShow, setModalAddShow] = useState(false);
    // const [newUserData, setNewUserData] = useState({ firstName: '', lastName: '', email: '', password: '' });
    // const [originalData, setOriginalData] = useState({}); // Store original data in case of cancel
    // const [currentUser, setCurrentUser] = useState(null);
    // const [passwordVisibility, setPasswordVisibility] = useState({}); // Store password visibility state
    // const [company, setCompany] = useState(null);
    // const [modalResetPasswordShow, setModalResetPasswordShow] = useState(false)
    // const [refresh, setRefresh] = useState(false);
    // const [showToast, setShowToast] = useState(false);
    // const [toastMsg, setToastMsg] = useState("");
    // const [toastType, setToastType] = useState("success");
    // const [loading, setLoading] = useState(true); 

    // useEffect(() => {
    //     getLoggedInUserCompany().then((r)=>{
    //         console.log(r)
    //         setCompany(r);
    //         fetchActiveCompanyUsers(r.companyID).then((d)=> {
    //             setUsers(d)
    //             setLoading(false)
    //         }).catch(e=>console.log(e));
    //     }).catch(e => console.log(e));
    // }, [refresh]);  // Depend on refresh state


    // const handleDelete = (user) => {
    //     setModalShow(true);
    //     setCurrentUser(user);
    // };

    // const confirmDelete = () => {
    //     // setUsers(users.filter(u => u.id !== currentUser.id));
    //     setLoading(true)
    //     deleteUser(currentUser.email).then(r=>{
    //         console.log(r)
    //         // setLoading(false)
    //         setRefresh(!refresh)
    //     }).catch(e=>console.log(e));
    //     setModalShow(false);
    // };

    // const handleEdit = (user) => {
    //     setEditUserId(user.id);
    //     setOriginalData({ ...user });  // Save original data
    // };

    // const handleChange = (e, id) => {
    //     const { name, value } = e.target;
    //     setUsers(users.map(user => user.id === id ? { ...user, [name]: value } : user));
    // };

    // const handleSave = () => {
    //     setEditUserId(null); // Save and exit edit mode
    // };

    // const handleCancel = () => {
    //     setUsers(users.map(user => user.id === editUserId ? { ...originalData } : user)); // Revert changes
    //     setEditUserId(null); // Exit edit mode
    // };

    // const handleAddNewUser = (newUserData) => {
    //     console.log(company)
    //     console.log("new data" + newUserData)
    //     setLoading(true)
    //     CreateUser(company.companyID,newUserData)
    //     .then((r)=>{
    //         console.log(r);
    //         setRefresh(!refresh);
    //     }).catch((e)=>{
    //         setToastMsg(e);
    //         setToastType("danger");
    //         setShowToast(true);
    //     })
    //     setModalAddShow(false);
    // };

    // const togglePasswordVisibility = (id) => {
    //     setPasswordVisibility(prev => ({
    //         ...prev,
    //         [id]: !prev[id]
    //     }));
    // };

    // const handleResetPassword = (user) => {
    //     setCurrentUser(user);
    //     setModalResetPasswordShow(true);
    // };

    // const saveNewPassword = (email, newPassword) => {
    //     UpdateUserPassword(email, newPassword).then(r=>console.log(r)).catch(e=>console.log(e))
    //     // Here you would call the service to update the password
    //     console.log(`Password for ${email} updated to ${newPassword}`);
    //     setModalResetPasswordShow(false);
    // };

    return (
        <div className="layout">
            <Navbar />
            <div className="content">
                <Sidebar />
                <div className="main-content">
                    <UserDetails />
                </div>
            </div>
        </div>
    );
};

export default Users;


