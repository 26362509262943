import React, { useEffect } from 'react';

const Toast = ({ message, type, show, onClose }) => {
    useEffect(() => {
        // Automatically close the toast after 3 seconds
        if (show) {
            const timer = setTimeout(() => {
                onClose();
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [show, onClose]);

    const getToastClassName = () => {
        switch (type) {
            case 'success':
                return 'bg-success text-white';
            case 'danger':
                return 'bg-danger text-white';
            case 'info':
                return 'bg-info text-white';
            default:
                return 'bg-light text-dark';
        }
    };

    return (
        <div aria-live="polite" aria-atomic="true" style={{ position: 'fixed', top: 10, right: 10, zIndex: 1050 }}>
            {show && (
                <div className={`toast show ${getToastClassName()}`} role="alert" aria-live="assertive" aria-atomic="true">
                    <div className="toast-header">
                        <strong className="me-auto">Notification</strong>
                        <button type="button" className="btn-close" onClick={onClose} aria-label="Close"></button>
                    </div>
                    <div className="toast-body">
                        {message}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Toast;
