import React, { useState } from 'react';

const AddDashboard = ({ show, onClose, onSave, company }) => {
    const [dashboardName, setDashboardName] = useState('');
    const [dashboardURL, setDashboardURL] = useState('');
    const [dashboardComments, setDashboardComments] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        onSave({
            dashboardId: 0,  // Assuming new dashboards will have an id of 0 initially
            dashboardName,
            dashboardURL,
            dashboardComments,
            companyID: company.companyID
        });
    };

    if (!show) return null;

    return (
        <div className="modal show d-block" tabIndex="-1" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Add New Dashboard for {company.companyName}</h5>
                        <button type="button" className="btn-close" onClick={onClose}></button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="dashboardName" className="form-label">Dashboard Name</label>
                                <input type="text" className="form-control" id="dashboardName" value={dashboardName} onChange={e => setDashboardName(e.target.value)} required />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="dashboardURL" className="form-label">Dashboard URL</label>
                                <input type="text" className="form-control" id="dashboardURL" value={dashboardURL} onChange={e => setDashboardURL(e.target.value)} required />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="dashboardComments" className="form-label">Comments</label>
                                <textarea className="form-control" id="dashboardComments" value={dashboardComments} onChange={e => setDashboardComments(e.target.value)} required />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={onClose}>Close</button>
                                <button type="submit" className="btn btn-primary">Save Dashboard</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddDashboard;