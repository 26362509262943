import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Navbar from '../Components/Navbar';
import Sidebar from '../Components/Sidebar';
import NotificationDetails from '../Components/NotificationDetails';
import { getLoggedInUserCompany } from '../Services/DataServices';
import { NotificationsDD } from '../Components/NotificationsDD';

const Notification = () => {
  const email = localStorage.getItem('email'); // Adjust based on how you store email
  const [addAccess, setAddAccess] = useState(false);
  const navigate = useNavigate();
  const [company, setCompany] = useState(null);

  useEffect(() => {
    getLoggedInUserCompany().then((d) => {
      setCompany(d);
    }).catch(e => console.log(e));
  }, []);  // Depend on refresh state

  return (
    <div className="layout">
            <Navbar />
            <div className="content">
                <Sidebar />
                <div className="main-content">
                <NotificationsDD company={null} />
                </div>
            </div>
        </div>
  );
};

export default Notification;
