import React, { useState } from 'react';

const AddCompany = ({ show, onClose, onSave }) => {
    const [companyName, setCompanyName] = useState('');
    const [uniqueClientId, setUniqueClientId] = useState('');
    const [pan, setPan] = useState('');
    // Set createdDate to today's date in the format YYYY-MM-DD
    const [createdDate, setCreatedDate] = useState(new Date().toISOString().slice(0, 10));

    const handleSubmit = (event) => {
        event.preventDefault();
        onSave({
            companyName,
            uniqueClientId,
            pan,
            createdDate: createdDate ? new Date(createdDate) : null,
            applicationUsers:[]
        });
    };

    if (!show) return null;

    return (
        <div className="modal show d-block" tabIndex="-1" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Add New Company</h5>
                        <button type="button" className="btn-close" onClick={onClose}></button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="companyName" className="form-label">Company Name</label>
                                <input type="text" className="form-control" id="companyName" value={companyName} onChange={e => setCompanyName(e.target.value)} required />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="uniqueClientId" className="form-label">Unique Client ID</label>
                                <input type="text" className="form-control" id="uniqueClientId" value={uniqueClientId} onChange={e => setUniqueClientId(e.target.value)} required />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="pan" className="form-label">PAN</label>
                                <input type="text" className="form-control" id="pan" value={pan} onChange={e => setPan(e.target.value)} required />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="createdDate" className="form-label">Created Date</label>
                                <input type="date" className="form-control" id="createdDate" value={createdDate} onChange={e => setCreatedDate(e.target.value)} disabled />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={onClose}>Close</button>
                                <button type="submit" className="btn btn-primary">Save Company</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddCompany;
