import React, { useState, useEffect } from 'react';
import Navbar from '../Components/Navbar';
import Sidebar from '../Components/Sidebar';
import { fetchScriptsForCompany } from '../Services/DataServices';  // Assume this function is correctly implemented to fetch scripts
import AddScript from './AddScript';
import { createScript } from '../Services/CreateServices';
import { updateScripts } from '../Services/UpdateServices';
import { deleteScript } from '../Services/DeleteServices';

const ScriptDetails = ({company}) => {
    const [scripts, setScripts] = useState([]);
    const [editScriptId, setEditScriptId] = useState(null);
    const [editedScripts, setEditedScripts] = useState({});
    const [modalShow, setModalShow] = useState(false);
    const [refresh, setRefresh] = useState(false)

    const handleSaveScript = (scriptData) => {
        console.log("Saving script:", scriptData);
        // Add an API call to save the script to the database
        setModalShow(false);  // Close modal on save
        createScript(scriptData)
            .then(r => {
                console.log(r);
                setRefresh(!refresh);  // Toggle the refresh state to trigger re-fetching of companies
            })
            .catch(e => console.log(e));
    };

    useEffect(() => {
        fetchScriptsForCompany(company.companyID).then(data => {
            setScripts(data);
            const scriptEdits = data.reduce((acc, script) => {
                acc[script.scriptId] = { ...script };
                return acc;
            }, {});
            setEditedScripts(scriptEdits);
        }).catch(e=>console.log(e));
    }, [refresh]);

    const handleEdit = (scriptId) => {
        setEditScriptId(scriptId);
    };

    const handleSave = (scriptId) => {
        // Optionally integrate API call to update the server
        console.log('Saving script', editedScripts[scriptId]);
        updateScripts(scriptId,editedScripts[scriptId])
            .then(r => {
                console.log(r);
                setRefresh(!refresh);  // Toggle the refresh state to trigger re-fetching of companies
            })
            .catch(e => console.log(e));
        setScripts(scripts.map(script => script.scriptId === scriptId ? { ...editedScripts[scriptId] } : script));
        setEditScriptId(null);
    };

    const handleCancel = (scriptId) => {
        const originalScript = scripts.find(script => script.scriptId === scriptId);
        setEditedScripts(prev => ({ ...prev, [scriptId]: { ...originalScript } }));
        setEditScriptId(null);
    };

    const handleDelete = (scriptId) => {
        // Optionally integrate API call to update the server
        setScripts(scripts.filter(script => script.scriptId !== scriptId));
        deleteScript(scriptId).then(r=> setRefresh(!refresh)).catch(e=>console.log(e))
    };

    const handleChange = (e, scriptId) => {
        const { name, value } = e.target;
        setEditedScripts(prev => ({
            ...prev,
            [scriptId]: { ...prev[scriptId], [name]: value }
        }));
    };

    return (
        <div className="container">
            <h2>Scripts for Company {company.companyName}</h2>
            <button className='btn btn-warning' onClick={() => setModalShow(true)}>Add Script</button>
            <AddScript
                show={modalShow} 
                onClose={() => setModalShow(false)} 
                onSave={handleSaveScript} 
                company={company}  // Pass the company name to the modal
            />
            <div className='table-cls'>
            <table className="table table-bordered table-responsive mt-3">
                <thead>
                    <tr>
                        <th>Script Name</th>
                        <th>Device Name</th>
                        <th>Machine Name</th>
                        <th>Notification Title</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {scripts.map(script => (
                        <tr key={script.scriptId}>
                            {editScriptId === script.scriptId ? (
                                <>
                                    <td><input type="text" name="scriptName" value={editedScripts[script.scriptId].scriptName} onChange={e => handleChange(e, script.scriptId)} /></td>
                                    <td><input type="text" name="deviceName" value={editedScripts[script.scriptId].deviceName} onChange={e => handleChange(e, script.scriptId)} /></td>
                                    <td><input type="text" name="machineName" value={editedScripts[script.scriptId].machineName} onChange={e => handleChange(e, script.scriptId)} /></td>
                                    <td><input type="text" name="notificationTitle" value={editedScripts[script.scriptId].notificationTitle} onChange={e => handleChange(e, script.scriptId)} /></td>
                                </>
                            ) : (
                                <>
                                    <td>{script.scriptName}</td>
                                    <td>{script.deviceName}</td>
                                    <td>{script.machineName}</td>
                                    <td>{script.notificationTitle}</td>
                                </>
                            )}
                            <td>
                                {editScriptId === script.scriptId ? (
                                    <>
                                        <button className="btn btn-success mx-2" onClick={() => handleSave(script.scriptId)}>Save</button>
                                        <button className="btn btn-warning" onClick={() => handleCancel(script.scriptId)}>Cancel</button>
                                    </>
                                ) : (
                                    <>
                                        <button className="btn btn-info mx-2" onClick={() => handleEdit(script.scriptId)}>Edit</button>
                                        <button className="btn btn-danger" onClick={() => handleDelete(script.scriptId)}>Delete</button>
                                    </>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            </div>
        </div>
    );
};

export default ScriptDetails;
