import React, { useEffect, useState } from "react";
import { fetchPhoneNumbersForScript } from "../Services/DataServices";
import { AddPhNumberToScript } from "../Services/CreateServices";
import { deletePhNumber } from "../Services/DeleteServices";
import { ToggleNumberActivation, TurnALLONOFF } from "../Services/UpdateServices";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Power, Stop } from "react-bootstrap-icons";
import "../Styles/PhoneNumbers.css";

export const ScriptNumbers = ({ scriptID }) => {
    const [notiNumbers, setNotiNumbers] = useState([]);
    const [error, setError] = useState("");
    const [newPhoneNumber, setNewPhoneNumber] = useState('');
    const [showAddNumber, setShowAddNumber] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [countries, setCountries] = useState(["in"]);

    useEffect(() => {
        console.log("Use effect called");
        fetchPhoneNumbersForScript(scriptID)
            .then((data) => {
                console.log(data)
                setNotiNumbers(data);
                setError("");  // Clear any previous errors
            })
            .catch((e) => setError(e.message || "Failed to fetch data"));  // Display a more user-friendly error message
    }, [scriptID,refresh]);


    const handleDelete = (notificationId) => {
        // Function to call API to delete the phone number
        console.log(`Delete notification with ID: ${notificationId}`);
        deletePhNumber(notificationId).then(r => {
            console.log(r);
            setRefresh(!refresh);  // Toggle the refresh state to trigger re-fetching of companies
        })
        .catch(e => console.log(e));
    };

    const toggleActive = (notificationId) => {
        // Function to toggle isActive status and call API to update
        ToggleNumberActivation(notificationId).then(r => {
            console.log(r);
            setRefresh(!refresh);  // Toggle the refresh state to trigger re-fetching of companies
        })
        .catch(e => console.log(e));
        console.log(`Toggle active status for ID: ${notificationId}`);
    };

    const handleAddNumber = () => {
        console.log(`Add phone number: ${newPhoneNumber} for script ID: ${scriptID}`);
        AddPhNumberToScript({
            scriptID,
            WhatsappNumber: newPhoneNumber
        }).then(r => {
            console.log(r);
            setRefresh(!refresh);  // Toggle the refresh state to trigger re-fetching of companies
        })
        .catch(e => console.log(e));
        // Here you would typically make an API call to save the new phone number
        // Simulating adding to list
        setNewPhoneNumber('');
        setShowAddNumber(false);
    };

    const handleAllON = () =>{
        TurnALLONOFF(scriptID,"on").then(r => {
            console.log(r);
            setRefresh(!refresh);  // Toggle the refresh state to trigger re-fetching of companies
        })
        .catch(e => console.log(e));
    }
    const handleAllOFF = () =>{
        TurnALLONOFF(scriptID,"off").then(r => {
            console.log(r);
            setRefresh(!refresh);  // Toggle the refresh state to trigger re-fetching of companies
        })
        .catch(e => console.log(e));
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div>
            <div className="own-flex justify-content-between mt-3 mb-2 pb-2 gap-2">
            <div className="own-flex align-items-center gap-3">    
            <button className={showAddNumber?"btn btn-secondary":"btn btn-primary"} onClick={() => setShowAddNumber(!showAddNumber)}>
                {showAddNumber ? 'Cancel Add' : 'Add Phone Number'}
            </button>
            {showAddNumber && (
                <div className="own-flex gap-3">
                    <PhoneInput
                        country={'in'}
                        value={newPhoneNumber}
                        onChange={(e) => setNewPhoneNumber(e)}
                        onlyCountries={countries}
                    />
                    {/* <input
                        type="text"
                        placeholder="Enter phone number"
                        value={newPhoneNumber}
                        onChange={(e) => setNewPhoneNumber(e.target.value)}
                    /> */}
                    <button className="btn btn-success" onClick={handleAddNumber}>Save</button>
                </div>
            )}</div>
            <div className="d-flex gap-3 align-items-center">
            <button className="btn btn-success" onClick={handleAllON}>Turn ON All</button>
            <button className="btn btn-secondary" onClick={handleAllOFF}>Turn OFF All</button>
            </div>
            </div>
            <table className="table table-bordered table-responsive">
                <thead>
                    <tr>
                        <th>Phone Number</th>
                        <th>Active Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {notiNumbers.map((notification) => (
                        <tr key={notification.notificationId}>
                            <td>{notification.phoneNumber}</td>
                            <td>
                                <span style={{ color: notification.isActive ? 'green' : 'gray' }}>
                                    {notification.isActive ? 'ON' : 'OFF'}
                                    <span style={{
                                        height: '10px',
                                        width: '10px',
                                        backgroundColor: notification.isActive ? 'green' : 'gray',
                                        borderRadius: '50%',
                                        display: 'inline-block',
                                        marginLeft: '10px'
                                    }}></span>
                                </span>
                            </td>
                            <td>
                                <button className="btn btn-secondary m-1" onClick={() => handleDelete(notification.notificationId)}>Delete</button>
                                {notification.isActive ? 
                                <button className="btn btn-danger m-1" onClick={() => toggleActive(notification.notificationId)}>Turn OFF <Stop /></button> 
                                : <button className="btn btn-success m-1" onClick={() => toggleActive(notification.notificationId)}>Turn ON <Power /></button>}
                                {/* <button onClick={() => toggleActive(notification.notificationId)}>
                                    {notification.isActive ? <>Turn off <Stop /></> : <>Turn On <Power /></>}
                                </button> */}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>

    );
};
