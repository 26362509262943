import React, { useEffect, useState } from 'react';
import ConfirmModal from './ConfirmModal';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import AddUser from './AddUser';
import { Eye, EyeSlash, Trash, ArrowClockwise, PersonPlusFill, PencilSquare } from 'react-bootstrap-icons';
import { fetchActiveCompanyUsers, getLoggedInUserCompany, fetchDashboardDetails } from '../Services/DataServices';
import { CreateUser } from '../Services/CreateServices';
import ResetPasswordModal from './ResetPasswordModal';
import { UpdateUserPassword, updateUserDashboard } from '../Services/UpdateServices';
import { deleteUser } from '../Services/DeleteServices';
import AppToast from './AppToast';

const UserDetails = ({ company: initialCompany }) => {
    const [users, setUsers] = useState([]);
    const [editUserId, setEditUserId] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const [modalAddShow, setModalAddShow] = useState(false);
    const [originalData, setOriginalData] = useState({});
    const [currentUser, setCurrentUser] = useState(null);
    const [passwordVisibility, setPasswordVisibility] = useState({});
    const [company, setCompany] = useState(null);
    const [modalResetPasswordShow, setModalResetPasswordShow] = useState(false);
    const [companyDashboards, setCompanyDashboards] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMsg, setToastMsg] = useState("");
    const [toastType, setToastType] = useState("success");
    const [editUserDashboard, setEditUserDashboard] = useState(null);
    const [loading, setLoading] = useState(false); // Loading state

    useEffect(() => {
        setLoading(true); // Start loading
        if (initialCompany) {
            setCompany(initialCompany);
            fetchActiveCompanyUsers(initialCompany.companyID)
                .then((d) => {setUsers(d)
                    console.log(d)
                })
                .catch(e => console.log(e));
            fetchDashboardDetails(initialCompany.companyID)
                .then(d => setCompanyDashboards(d))
                .catch(e => console.log(e))
                .finally(() => setLoading(false)); // Stop loading
        } else {
            getLoggedInUserCompany().then((r) => {
                setCompany(r);
                fetchActiveCompanyUsers(r.companyID)
                    .then(d => setUsers(d))
                    .catch(e => console.log(e));
                fetchDashboardDetails(r.companyID)
                    .then(d => setCompanyDashboards(d))
                    .catch(e => console.log(e))
                    .finally(() => setLoading(false)); // Stop loading
            }).catch(e => console.log(e));
        }
    }, [refresh]);

    const handleDelete = (user) => {
        setModalShow(true);
        setCurrentUser(user);
    };

    const confirmDelete = () => {
        setUsers(users.filter(u => u.id !== currentUser.id));
        deleteUser(currentUser.email).then(r => console.log(r)).catch(e => console.log(e));
        setModalShow(false);
        setRefresh(!refresh);
    };

    const handleEdit = (user) => {
        console.log(user)
        setEditUserId(user.email);
        setOriginalData({ ...user });
        setEditUserDashboard(user.UserDashboardName); // Set the initial dashboard name for edit
    };

    const handleChange = (e, id) => {
        const { name, value } = e.target;
        setUsers(users.map(user => user.id === id ? { ...user, [name]: value } : user));
    };

    const handleDashboardChange = (e) => {
        setEditUserDashboard(e.target.value);
    };

    const handleSave = () => {
        const updatedUser = users.find(user => user.email === editUserId);
        if(!editUserDashboard)
        {    
            return;
        }
        const selectedDashboard = companyDashboards.find(d => d.dashboardID.toString() === editUserDashboard);

        // console.log(updatedUser.email);
        updateUserDashboard(updatedUser.email, selectedDashboard.dashboardID).then(r => {
            // console.log(r);
            setRefresh(!refresh);
            setShowToast(true);
            setToastMsg("User dashboard updated successfully!");
            setToastType("success");
        }).catch(e => {
            setShowToast(true);
            setToastMsg(e.message);
            setToastType("danger");
        });
        setEditUserId(null);
    };

    const handleCancel = () => {
        setUsers(users.map(user => user.id === editUserId ? { ...originalData } : user));
        setEditUserId(null);
        setEditUserDashboard(null);
    };

    const handleAddNewUser = (newUserData) => {
        CreateUser(company.companyID, newUserData)
            .then((r) => {
                console.log(r);
                setRefresh(!refresh);
                setShowToast(true);
                setToastMsg("New user added successfully!");
                setToastType("success");
            }).catch((e) => {
                setToastMsg(e.message);
                setToastType("danger");
                setShowToast(true);
            });
        setModalAddShow(false);
    };

    const handleResetPassword = (user) => {
        setCurrentUser(user);
        setModalResetPasswordShow(true);
    };

    const saveNewPassword = (email, newPassword) => {
        UpdateUserPassword(email, newPassword).then(r => {
            console.log(r);
            setShowToast(true);
            setToastMsg("Password updated successfully!");
            setToastType("success");
        }).catch(e => {
            setShowToast(true);
            setToastMsg(e.message);
            setToastType("danger");
        });
        setModalResetPasswordShow(false);
    };

    const refreshComp = () =>{
        setRefresh(!refresh);
    }

    return (
        <div className="container">
            <div className='container pt-3'>
                <h2>User Accounts</h2>
                <div className='d-flex align-items-center'>
                <button className="btn btn-primary my-3" onClick={() => setModalAddShow(true)}>Add New User <PersonPlusFill /></button>
                <span className="ms-auto refreshbtn" onClick={refreshComp}>Refresh<ArrowClockwise /></span>
                </div>
                {loading ? (
                    <div className='d-flex justify-content-center'><div className="loader"></div></div> // Loader element
                ) : (
                    <div className='table-cls'>
                    <table className='table table-bordered table-cls' width="90%">
                        <thead>
                            <tr>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email</th>
                                <th>Dashboard</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map(user => (
                                <tr key={user.id}>
                                    {editUserId === user.email ? (
                                        <>
                                            <td>{user.firstName}</td>
                                            <td>{user.lastName}</td>
                                            <td>{user.email}</td>
                                            <td>
                                                <select className="form-control" value={editUserDashboard || ''} onChange={handleDashboardChange}>
                                                    <option value="">Select Dashboard</option>
                                                    {companyDashboards.map(dash => (
                                                        <option key={dash.dashboardID} value={dash.dashboardID}>
                                                            {dash.dashboardName}
                                                        </option>
                                                    ))}
                                                </select>
                                            </td>
                                        </>
                                    ) : (
                                        <>
                                            <td>{user.firstName}</td>
                                            <td>{user.lastName}</td>
                                            <td>{user.email}</td>
                                            <td>
                                                
                                                    <span>{user.userDashboardName}</span>
                                                    <button className="btn btn-primary ms-auto" onClick={() => handleEdit(user)}><PencilSquare /></button>
                                                
                                                 </td>
                                        </>
                                    )}
                                    <td>
                                        {editUserId === user.email ? (
                                            <>
                                                <button className="btn btn-success mx-2" onClick={handleSave}>Save</button>
                                                <button className="btn btn-warning" onClick={handleCancel}>Cancel</button>
                                            </>
                                        ) : (
                                            <>
                                                <button className="btn btn-secondary" onClick={() => handleResetPassword(user)}>Reset Password <ArrowClockwise /> </button>
                                                <button className="btn btn-danger" onClick={() => handleDelete(user)}><Trash /></button>
                                            </>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    </div>

                )}
                {modalShow && (
                    <ConfirmModal
                        show={modalShow}
                        onClose={() => setModalShow(false)}
                        onCancel={() => setModalShow(false)}
                        onConfirm={confirmDelete}
                        title="Confirm Deletion"
                    >
                        Are you sure you want to delete {currentUser?.firstName} {currentUser?.lastName}?
                    </ConfirmModal>
                )}
                {modalAddShow && (
                    <AddUser show={modalAddShow} onClose={() => setModalAddShow(false)} onSave={handleAddNewUser} />
                )}
                {modalResetPasswordShow && (
                    <ResetPasswordModal
                        show={modalResetPasswordShow}
                        onClose={() => setModalResetPasswordShow(false)}
                        onSave={saveNewPassword}
                        onCancel={() => setModalResetPasswordShow(false)}
                        user={currentUser}
                    />
                )}
                <AppToast
                    message={toastMsg}
                    type={toastType}
                    show={showToast}
                    onClose={() => setShowToast(false)}
                />
            </div>
        </div>
    );
};

export default UserDetails;
