import React, { useState } from 'react';
import '../Styles/ResetPasswordModal.css';
import { EyeSlash, EyeFill, Copy } from 'react-bootstrap-icons';

const ResetPasswordModal = ({ show, onClose, onSave, onCancel, user }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";
  const [newPassword, setNewPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});

  const validatePassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return regex.test(password);
  };

  const handleSave = () => {
    const newErrors = {};
    if (!validatePassword(newPassword)) newErrors.password = 'Password must be at least 8 characters and include at least one uppercase letter, one lowercase letter, one number, and one special character.';

    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      onSave(user.email, newPassword);
      setNewPassword('');
    }
    // You can add additional validation for newPassword here if needed
    // onSave(user.email, newPassword);
    // setNewPassword('');  // Clear password after saving
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const copyToClipboard = (emailID) => {
    const formattedText = `Email: ${emailID}\nPassword: ${newPassword}`;
    // navigator.clipboard.writeText(formattedText);
    if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(formattedText)
          .then(() => {
            alert("Details copied to clipboard successfully!");
          })
          .catch(err => {
            console.error("Failed to copy text: ", err);
            // Fallback method (optional)
            fallbackCopyText(formattedText);
          });
      } else {
        // Fallback method (optional)
        fallbackCopyText(formattedText);
      }
};

const fallbackCopyText = (text) => {
    // Create a temporary textarea element
    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    textarea.setSelectionRange(0, 99999); // For mobile devices
    try {
      document.execCommand('copy');
      alert("Details copied to clipboard successfully!");
    } catch (err) {
      alert("Oops, unable to copy", err);
    }
    document.body.removeChild(textarea);
  };

  return (
    <div className={showHideClassName} tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Reset Password</h5>
            <button type="button" className="btn-close" onClick={onClose}></button>
          </div>
          <div className="modal-body">
            <form>
              <div className="mb-3">
                <label htmlFor="firstName" className="form-label">First Name</label>
                <input type="text" className="form-control" id="firstName" value={user.firstName} disabled />
              </div>
              <div className="mb-3">
                <label htmlFor="lastName" className="form-label">Last Name</label>
                <input type="text" className="form-control" id="lastName" value={user.lastName} disabled />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">Email</label>
                <input type="email" className="form-control" id="email" value={user.email} disabled />
              </div>
              <div className="mb-3">
                <label htmlFor="newPassword" className="form-label">New Password</label>
                <div className='d-flex gap-2 align-items-center'>
                  <input type={showPassword ? "text" : "password"} className="form-control" id="newPassword" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                  <button type="button" className="btn btn-secondary featureBtn" onClick={togglePasswordVisibility}>
                    {showPassword ? <EyeSlash /> : <EyeFill />}
                  </button>
                  <button type="button" className="btn btn-secondary featureBtn" onClick={()=>copyToClipboard(user.email)}>
                    <Copy />
                  </button>
                </div>
                {errors.password && <div className="text-danger">{errors.password}</div>}
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={onCancel}>
              Cancel
            </button>
            <button type="button" className="btn btn-primary" onClick={handleSave}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordModal;
