import React from 'react';
import { useNavigate } from 'react-router-dom';

const CompanyCard = ({ company }) => {
    const navigate = useNavigate();

    const handleCardClick = () => {
        navigate(`/company/${company.companyID}`);
    };

    return (
        <div className="col-md-4" onClick={handleCardClick} style={{ cursor: 'pointer' }}>
            <div className="card mb-4">
                <div className="card-body">
                    <h5 className="card-title"><strong>{company.companyName}</strong></h5>
                    <p className="card-text">Unique Client ID: {company.uniqueClientID}</p>
                    <p className="card-text">PAN: {company.pan}</p>
                </div>
            </div>
        </div>
    );
};

export default CompanyCard;
