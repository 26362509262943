import React, { useState, useEffect } from 'react';
import { fetchDashboardDetails } from '../Services/DataServices';
import { createDashboard } from '../Services/CreateServices';
import { updateDashboardDetails } from '../Services/UpdateServices'; // Assume createDashboard is implemented
import AddDashboard from './AddDashboard'; // Import the AddDashboard component
import { deleteDashboard } from '../Services/DeleteServices';

function DashboardEdit({ companyId }) {
    const [dashboards, setDashboards] = useState([]);
    const [editDashboardId, setEditDashboardId] = useState(null);
    const [editedDashboards, setEditedDashboards] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        fetchDashboardDetails(companyId).then(data => {
            setDashboards(data);
            const dashboardEdits = data.reduce((acc, dashboard) => {
                acc[dashboard.dashboardID] = { ...dashboard };
                return acc;
            }, {});
            setEditedDashboards(dashboardEdits);
            
        });
    }, [companyId, refresh]);

    const handleInputChange = (e, dashboardId) => {
        const { name, value } = e.target;
        setEditedDashboards(prev => ({
            ...prev,
            [dashboardId]: { ...prev[dashboardId], [name]: value }
        }));
    };

    const handleEdit = (dashboardId) => {
        setEditDashboardId(dashboardId);
        console.log(editedDashboards);
        setIsEditing(true);
    };

    const handleDelete = (dashboardId) => {
        deleteDashboard(dashboardId).then(r=> setRefresh(!refresh)).catch(e=>console.log(e))
    };

    const handleSave = (dashboardId) => {
        updateDashboardDetails(companyId, editedDashboards[dashboardId])
            .then(response => {
                console.log('Dashboard updated successfully:', response.data);
                // setDashboards(dashboards.map(dashboard => dashboard.dashboardId === dashboardId ? { ...editedDashboards[dashboardId] } : dashboard));
                setEditDashboardId(null);
                setIsEditing(false);
                setRefresh(!refresh); // Trigger refresh
            })
            .catch(error => {
                console.error('Failed to update dashboard:', error);
            });
    };

    const handleCancel = (dashboardId) => {
        const originalDashboard = dashboards.find(dashboard => dashboard.dashboardId === dashboardId);
        // setEditedDashboards(dashboards);
        setEditDashboardId(null);
        setIsEditing(false);
    };

    const handleSaveDashboard = (dashboardData) => {
        console.log("Saving dashboard:", dashboardData);
        createDashboard(dashboardData)
            .then(response => {
                console.log('Dashboard created successfully:', response.data);
                setRefresh(!refresh);  // Toggle the refresh state to trigger re-fetching of dashboards
                setModalShow(false);   // Close the modal on save
            })
            .catch(error => console.error('Failed to create dashboard:', error));
    };

    return (
        <div className='container'>
            <h1>Dashboard Configuration</h1>
            <p>Here you can view or edit the configurations for dashboards.</p>
            <button className="btn btn-warning" onClick={() => setModalShow(true)}>Add Dashboard</button>
            <AddDashboard
                show={modalShow}
                onClose={() => setModalShow(false)}
                onSave={handleSaveDashboard}
                company={{ companyID: companyId, companyName: 'Company Name' }} // Replace 'Company Name' with actual company name if available
            />

            <table className="table table-responsive table-bordered mt-3 table-Dashboard">
                <thead>
                    <tr>
                        <th>Dashboard Name</th>
                        <th>Dashboard URL</th>
                        <th>Comments</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {dashboards.map(dashboard => (
                        <tr key={dashboard.dashboardID}>
                            {editDashboardId === dashboard.dashboardID ? (
                                <>
                                    <td>
                                        <input 
                                            type="text" 
                                            name="dashboardName" 
                                            value={editedDashboards[dashboard.dashboardID].dashboardName} 
                                            onChange={e => handleInputChange(e, dashboard.dashboardID)} 
                                        />
                                    </td>
                                    <td>
                                        <input 
                                            type="text" 
                                            name="dashboardURL" 
                                            value={editedDashboards[dashboard.dashboardID].dashboardURL} 
                                            onChange={e => handleInputChange(e, dashboard.dashboardID)} 
                                        />
                                    </td>
                                    <td>
                                        <input 
                                            type="text" 
                                            name="dashboardComments" 
                                            value={editedDashboards[dashboard.dashboardID].dashboardComments} 
                                            onChange={e => handleInputChange(e, dashboard.dashboardID)} 
                                        />
                                    </td>
                                </>
                            ) : (
                                <>
                                    <td>{dashboard.dashboardName}</td>
                                    <td>{dashboard.dashboardURL}</td>
                                    <td>{dashboard.dashboardComments}</td>
                                </>
                            )}
                            <td>
                                {editDashboardId === dashboard.dashboardID ? (
                                    <>
                                        <button className="btn btn-success mx-2" onClick={() => handleSave(dashboard.dashboardID)}>Save</button>
                                        <button className="btn btn-warning" onClick={() => handleCancel(dashboard.dashboardID)}>Cancel</button>
                                    </>
                                ) : (
                                    <>
                                        <button className="btn btn-info mx-2" onClick={() => handleEdit(dashboard.dashboardID)}>Edit</button>
                                        <button className="btn btn-danger" onClick={() => handleDelete(dashboard.dashboardID)}>Delete</button>
                                    </>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default DashboardEdit;
