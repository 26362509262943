import axios from "./AxiosSetup";

export const deleteCompany = async (companyID) =>{
    const authToken = localStorage.getItem('token');

    // Set up the Axios configuration for the POST request
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`  // Assume the token is a Bearer token
        }
    };

    // URL of the API endpoint
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/Companies/${companyID}`; // Replace with your actual API endpoint
    return axios.delete(apiUrl, config)
        .then(response => {
            console.log('Company deleted successfully:', response.data);
            return response.data;  // Return the response data
        })
        .catch(error => {
            console.error('Error deleting company:', error.response ? error.response.data : error);
            throw error;  // Re-throw the error to be handled by the caller
        });
    
}

export const deleteScript = async (scriptID) =>{
    const authToken = localStorage.getItem('token');

    // Set up the Axios configuration for the POST request
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`  // Assume the token is a Bearer token
        }
    };

    // URL of the API endpoint
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/Script/${scriptID}`; // Replace with your actual API endpoint
    return axios.delete(apiUrl, config)
        .then(response => {
            console.log('Company deleted successfully:', response.data);
            return response.data;  // Return the response data
        })
        .catch(error => {
            console.error('Error deleting company:', error.response ? error.response.data : error);
            throw error;  // Re-throw the error to be handled by the caller
        });
    
}

export const deleteDashboard = async (dashboardID) =>{
    const authToken = localStorage.getItem('token');

    // Set up the Axios configuration for the POST request
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`  // Assume the token is a Bearer token
        }
    };

    // URL of the API endpoint
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/Dashboard/${dashboardID}`; // Replace with your actual API endpoint
    return axios.delete(apiUrl, config)
        .then(response => {
            console.log('Dashboard deleted successfully:', response.data);
            return response.data;  // Return the response data
        })
        .catch(error => {
            console.error('Error deleting company:', error.response ? error.response.data : error);
            throw error;  // Re-throw the error to be handled by the caller
        });
    
}


export const deleteUser = async(userEmail) =>{
    const authToken = localStorage.getItem('token');

    // Set up the Axios configuration for the POST request
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`  // Assume the token is a Bearer token
        }
    };

    // URL of the API endpoint
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/Account/delete/${userEmail}`; // Replace with your actual API endpoint
    return axios.delete(apiUrl, config)
        .then(response => {
            console.log('user deleted successfully:', response.data);
            return response.data;  // Return the response data
        })
        .catch(error => {
            console.error('Error deleting user:', error.response ? error.response.data : error);
            throw error;  // Re-throw the error to be handled by the caller
        });
}

export const deletePhNumber = async(notiID) =>{
    const authToken = localStorage.getItem('token');

    // Set up the Axios configuration for the POST request
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`  // Assume the token is a Bearer token
        }
    };

    // URL of the API endpoint
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/Notification/${notiID}`; // Replace with your actual API endpoint
    return axios.delete(apiUrl, config)
        .then(response => {
            console.log('user deleted successfully:', response.data);
            return response.data;  // Return the response data
        })
        .catch(error => {
            console.error('Error deleting user:', error.response ? error.response.data : error);
            throw error;  // Re-throw the error to be handled by the caller
        });
}