import React from 'react';

function Register() {
  return (
    <div>
      <h1>Welcome to the Register Page</h1>
      <p>This is a simple Register page component in React.</p>
    </div>
  );
}

export default Register;
