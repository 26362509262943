import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Navbar from '../Components/Navbar';
import Sidebar from '../Components/Sidebar';
import { fetchUserDashboard } from '../Services/DataServices';
import '../Styles/dashboard.css';
import { ArrowsAngleExpand, X } from 'react-bootstrap-icons';


const Dashboard = () => {
  const [dashboards, setDashboards] = useState(null);
  const [loading, setLoading] = useState(true);
  const [multiDash, setMultiDash] = useState(false);
  const [activeDash, setActiveDash] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const handleButtonClick = () => {
    setIsFullscreen(true);
  };

  const handleCloseClick = () => {
    setIsFullscreen(false);
  };
    
  
  useEffect(() => {
    fetchUserDashboard().then((r)=>{
        console.log(r)
        if(r.length >= 1)
          {
            setActiveDash(r[0])
          }
        if(r.length>=2)
            {
              setMultiDash(true)
            }
        setDashboards(r);
        setLoading(false);
    }).catch(e => console.log(e));
}, []);  // Depend on refresh state

const handleCardClick = (dash)=>{
  setActiveDash(dash);
}

  return (
    <div className="layout">
      <Navbar />
      <div className="content">
        <Sidebar />
        <div className="main-content">
        {!loading && <div> {/* Adjust marginLeft according to sidebar width */}
      {/* <h1>Dashboard</h1> */}
      {multiDash && <div className='container row'>
        {dashboards.map(dashboard=>(
          <div className="col" onClick={()=>handleCardClick(dashboard)} style={{ cursor: 'pointer' }}>
          <div className={activeDash.dashboardID===dashboard.dashboardID ? "card mb-4 bg-primary text-white":"card mb-4"}>
              <div className="card-body">
                  <h5 className="card-title">{dashboard.dashboardName}</h5>
                  <p className="card-text">Comments: {dashboard.dashboardComments}</p>
              </div>
          </div>
      </div>
        ))}
        </div>}
      <p className='text-center'>{activeDash.dashboardName ?? ''}</p>
      {isFullscreen?(
        <div className="fullscreen-iframe">
          <iframe
            src={activeDash.dashboardURL}
            title="Fullscreen Iframe"
            width="100%"
            height="100%"
          />
          <button className="close-button" onClick={handleCloseClick}>
            <X />
          </button>
        </div>):(<><div className="ratio ratio-1x1">
      <iframe  title="User Dashboard"  src={activeDash.dashboardURL} frameborder="0" allowFullScreen={true}></iframe>
      </div>
    <button className="floating-button" onClick={handleButtonClick}>
    <ArrowsAngleExpand />
  </button></>
    )}

      
      </div>}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
